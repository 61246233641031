import React from "react"
import {
  Section,
  Hero,
  Heading,
  Link,
  Kicker,
  ProductCard,
  CallToAction,
  Image,
  Button,
} from "components"
import { useStaticQuery, graphql } from "gatsby"

const HomePage = () => {
  const {
    image,
    lab,
    allSanityDepartment: { edges: departments },
    allSanitySolution: { edges: solutions },
    allSanityMarket: { edges: markets },
  } = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "home.png" }) {
        asset: childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            src
          }
        }
      }
      lab: file(relativePath: { eq: "table.jpg" }) {
        asset: childImageSharp {
          fluid {
            src
          }
        }
      }
      allSanityDepartment(sort: { order: [ASC, ASC], fields: [order, title] }) {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
      allSanitySolution(sort: { order: [ASC, ASC], fields: [order, title] }) {
        edges {
          node {
            title
            slug {
              current
            }
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
      allSanityMarket(sort: { order: [ASC, ASC], fields: [order, title] }) {
        edges {
          node {
            title
            slug {
              current
            }
            _rawParent
            image {
              asset {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Hero
        title="Resultado líquido para o seu negócio"
        description={
          <>
            Bem vindo a Bondmann.
            <br />
            <br />
            Somos especialistas em soluções químicas voltadas a facilitação e
            qualificação de processos dos mais variados tipos de operação,
            manutenções e higienizações com a máxima efetividade e
            sustentabilidade, trazendo mais resultado a seu negócio.
            <br />
            <br />
            Conheça nosso portfólio e navegue por nossas submarcas, perfil de
            produtos ou ainda soluções adequados a cada segmento de negócio.
          </>
        }
        image={image}
        extraPadding
        kicker="Bondmann Química"
      />

      <div className="container grid md:grid-cols-3 lg:grid-cols-6 gap-8 mt-16 lg:-mt-16 mb-16">
        {departments.map(({ node: { title, slug } }) => (
          <div
            key={slug.current}
            className="pt-32 relative rounded-sm overflow-hidden transform transition hover:-translate-y-1 shadow-soft"
          >
            <Link
              to={`/${slug.current}`}
              className={`inset-0 absolute flex items-end p-4 ${slug.current} shadow-soft`}
            >
              <Heading
                level="4"
                className="text-white font-medium text-xl mb-0"
              >
                {title}
              </Heading>
            </Link>
          </div>
        ))}
      </div>

      <Section>
        <div className="grid lg:grid-cols-3 gap-16 items-center">
          <div className="space-y-6">
            <Kicker children="conheça a Bondmann" />
            <Heading>Química é comportamento</Heading>
            <p>Qualidade e eficiência que lhe traz competitividade.</p>
            <p>
              Temos como premissa desenvolver soluções que atendam rigorosos
              padrões de qualidade, sempre visando a resolução de desafios de
              operação dos negócios.
            </p>
            <p>
              Com forte orientação e suporte, nossa equipe cobre o Brasil
              entregando com um atendimento marcante suportado por uma agilidade
              impecável.
            </p>
            <Button to="/a-bondmann">Saiba mais</Button>
          </div>
          <div className="aspect-ratio-16/9 lg:col-span-2 relative mb-8  rounded-sm overflow-hidden shadow-soft border-b-4 border-accent-500">
            <div className="absolute inset-0">
              {/* <ReactPlayer
                url="https://www.youtube.com/watch?v=O7iU2c9Knqc"
                width="100%"
                height="100%"
              /> */}
              <Image className="w-full h-full" source={lab} />
            </div>
          </div>
        </div>
      </Section>

      <Section background="bg-gray-100">
        <div className="max-w-lg space-y-6">
          <Kicker children="Para o seu negócio" />
          <Heading>Conheça as Soluções da Bondmann</Heading>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cold-4 xl:grid-cols-5 gap-8 py-8">
          {solutions.map(({ node: { title, slug, image } }) => (
            <ProductCard
              key={slug.current}
              title={title}
              to={`/solucoes/${slug.current}`}
              image={image}
            />
          ))}
        </div>
      </Section>

      <Section>
        <div className="max-w-lg space-y-6">
          <Kicker children="Segmenetos de Atuação" />
          <Heading>Conheça o produto ideal para o seu negócio</Heading>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cold-4 xl:grid-cols-4 gap-8 py-8">
          {markets
            .filter(market => market.node._rawParent === null)
            .map(({ node: { title, slug, image } }) => (
              <ProductCard
                key={slug.current}
                title={title}
                to={`/produtos-para-${slug.current}`}
                image={image}
              />
            ))}
        </div>
      </Section>

      <CallToAction />
    </>
  )
}

export default HomePage
