import React from "react"
import { Section } from "components"
import { useStaticQuery, graphql } from "gatsby"

const SearchPage = ({ location }) => {
  const searchParam = new URLSearchParams(location.search).get("q")
  const data = useStaticQuery(graphql`
    {
      allSitePage {
        edges {
          node {
            context {
              slug
            }
          }
        }
      }
    }
  `)

  // console.log(kebabCase(searchParam))

  // console.log(data.allSitePage.edges.map(page => page.node.context.slug))

  return (
    <>
      <Section>search</Section>
    </>
  )
}

export default SearchPage
