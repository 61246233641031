import React from "react"
import { SEO, Section, BlockContent } from "components"

const Policies = ({ data }) => {
  return (
    <>
      <SEO title={data.title} />

      <Section>
        {data?._rawContent && <BlockContent blocks={data._rawContent} />}
      </Section>
    </>
  )
}

export default Policies
