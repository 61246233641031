import React, { useState, useEffect } from "react"
import { Section, ProductCard, FilterGroup } from "components"
import uniqueValuesFromArray from "utils/uniqueValuesFromArray"
import { AnimatePresence } from "framer-motion"

const ProductPage = ({ data }) => {
  const defaultObj = {
    categories: [],
    departments: [],
    markets: [],
    solutions: [],
  }

  const isFiltered = JSON.parse(JSON.stringify(defaultObj))

  const mapFilters = data => {
    const obj = JSON.parse(JSON.stringify(defaultObj))
    data.map(
      ({
        node: {
          categories: $categories,
          department: $departments,
          markets: $markets,
          solutions: $solutions,
        },
      }) => {
        obj.departments.push($departments)
        $categories.map(categories => obj.categories.push(categories))
        $markets.map(markets => obj.markets.push(markets))
        $solutions.map(solutions => obj.solutions.push(solutions))
      }
    )

    // Map and assign unique values to the filters based on current product list
    obj.departments = uniqueValuesFromArray(obj.departments)
    obj.categories = uniqueValuesFromArray(obj.categories)
    obj.markets = uniqueValuesFromArray(obj.markets)
    obj.solutions = uniqueValuesFromArray(obj.solutions)

    return obj
  }

  // State Hooks
  const [filters, setFilters] = useState(mapFilters(data))
  const [selectedFilters, setSelectedFilters] = useState(isFiltered)
  const [products, setProducts] = useState(data)

  const handleChange = (key, value, checked) => {
    if (checked) {
      setSelectedFilters(prevState => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            [value]: checked,
          },
        }
      })
    } else {
      setSelectedFilters(prevState => {
        delete prevState[key][value]
        return {
          ...prevState,
        }
      })
    }

    // Render all products if there's no checkbox selected
  }

  const handleFilter = arr => {
    let results = arr.filter(products => {
      const filters = {}
      Object.entries(selectedFilters).map(object => {
        const [keys, values] = object
        const valueKeys = Object.keys(values)
        const objKey = keys === "departments" ? "department" : [keys]
        // return [objKey]: valueKeys

        const match = [].concat(
          keys === "departments"
            ? products.node[objKey].slug.current
            : products.node[objKey].map(key => key.slug.current)
        )

        let matches = valueKeys.every(el => match.includes(el))

        return (filters[objKey] = {
          filter: valueKeys,
          matches,
        })
      })

      return (
        filters.categories.matches &&
        filters.department.matches &&
        filters.solutions.matches &&
        filters.markets.matches
      )
    })

    return setProducts(results.length === 0 ? data : results)
  }

  useEffect(() => {
    handleFilter(data)
  }, [selectedFilters])

  useEffect(() => {
    setFilters(mapFilters(products))
  }, [products])

  return (
    <div className="relative">
      <Section>
        <div className="flex -mx-8">
          <div className="hidden lg:block w-3/12 px-8 h-full">
            <FilterGroup
              onChange={handleChange}
              title="Departamentos"
              filters={filters.departments}
              where="departments"
            />

            <FilterGroup
              onChange={handleChange}
              title="Soluções"
              filters={filters.solutions}
              where="solutions"
            />
            <FilterGroup
              onChange={handleChange}
              title="Mercados"
              filters={filters.markets}
              where="markets"
            />
            <FilterGroup
              onChange={handleChange}
              title="Categorias"
              filters={filters.categories}
              where="categories"
            />
          </div>

          <div className="w-full lg:w-9/12 h-full px-8 grid items-start col-span-9 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
            {products.map(({ node }) => {
              return (
                <AnimatePresence key={node.slug.current}>
                  <ProductCard
                    title={node.title}
                    to={`/produtos/${node.slug.current}`}
                    department={node.department}
                    image={node.image}
                    categories={node.categories}
                    solutions={node.solutions}
                    markets={node.markets}
                  />
                </AnimatePresence>
              )
            })}
          </div>
        </div>
      </Section>
    </div>
  )
}

export default ProductPage
