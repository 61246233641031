import React from "react"
import { Hero, SEO } from "components"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import HomePage from "./temp/home"
import ContactPage from "./temp/contact"
import LeafletPage from "./temp/leaflet"
import NewsPage from "./temp/news"
import ProductPage from "./temp/product"
import AboutPage from "./temp/about"
import SearchPage from "./temp/searchresults"
import Policies from "./temp/policies"

const PageTemplate = ({ data, location }) => {
  const products = data.products.edges

  console.log(data.sanityPage.slug.current)

  const { title, image } = data.sanityPage

  return (
    <>
      <SEO title={title} />
      {"/" !== data.sanityPage.slug.current &&
        "busca" !== data.sanityPage.slug.current && (
          <Hero title={title} image={image && image} overlay />
        )}

      {/* Home */}
      {"/" === data.sanityPage.slug.current && <HomePage />}

      {"busca" === data.sanityPage.slug.current && (
        <SearchPage location={location} />
      )}

      {/* Products */}
      {"produtos" === data.sanityPage.slug.current && (
        <ProductPage
          data={products}
          department={data.allSanityDepartment.edges}
          solution={data.allSanitySolution.edges}
          category={data.allSanityCategory.edges}
          market={data.allSanityMarket.edges}
        />
      )}

      {/* About */}
      {"a-bondmann" === data.sanityPage.slug.current && <AboutPage />}

      {/* Contact */}
      {"contato" === data.sanityPage.slug.current && (
        <ContactPage data={data.sanityPage} />
      )}
      {"catalogos" === data.sanityPage.slug.current && (
        <LeafletPage data={data.sanityPage} />
      )}

      {"conteudos" === data.sanityPage.slug.current && (
        <NewsPage data={data.sanityPage} />
      )}

      {"politica-de-privacidade" === data.sanityPage.slug.current && (
        <Policies data={data.sanityPage} />
      )}

      {"politica-de-qualidade" === data.sanityPage.slug.current && (
        <Policies data={data.sanityPage} />
      )}
    </>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

export default PageTemplate

export const query = graphql`
  query GET_PAGE($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      image {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawContent
    }
    products: allSanityProduct(
      sort: { fields: title, order: ASC }
      filter: { department: { slug: { current: { ne: "bd-service" } } } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
          markets {
            title
            slug {
              current
            }
          }
          department {
            title
            slug {
              current
            }
          }
          solutions {
            title
            slug {
              current
            }
          }
          categories {
            title
            slug {
              current
            }
          }
        }
      }
    }
    allSanityMarket(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          title
          _rawParent
        }
      }
    }
    allSanityCategory(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          title
        }
      }
    }
    allSanitySolution(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          title
        }
      }
    }
    allSanityDepartment(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          title
        }
      }
    }
  }
`
