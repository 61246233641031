import React from "react"
import { FaMapMarkerAlt, FaHeadset } from "react-icons/fa"
import { useStaticQuery, graphql } from "gatsby"
import {
  SEO,
  Section,
  Image,
  Heading,
  Form,
  Link,
  Kicker,
  BackgroundImage,
} from "components"

const Contato = ({ data }) => {
  const { title, heading, content } = tempData
  const { map, banner } = useStaticQuery(staticQuery)

  return (
    <>
      <SEO title={title} />

      <Section>
        <div className="grid lg:grid-cols-3 gap-24">
          <div className="lg:col-span-2">
            <Form
              name="contato"
              title={heading}
              headingLevel="2"
              description={content}
              button="Enviar mensagem"
              messageField
            />

            <div className="mt-16 relative bg-primary-500 p-16">
              {banner && <BackgroundImage className="z-10" source={banner} />}

              <div className="relative z-20 w-1/2">
                <Heading level="4" className="text-accent-500">
                  Trabalhe conosco
                </Heading>

                <p className="text-white mb-0 text-lg">
                  Faça parte do time da Bondmann. Envie seu currículo para o
                  email{" "}
                  <Link
                    className="text-accent-500"
                    to="mailto:rh@bondmann.com.br"
                  >
                    rh@bondmann.com.br
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-16">
              <div className="flex items-center space-x-2">
                <FaMapMarkerAlt className="text-2xl mb-6 text-accent-500" />
                <Heading level="3">Onde estamos</Heading>
              </div>

              <div className="space-y-6">
                <Kicker>Rio Grande do Sul</Kicker>

                <Image className="mb-6" source={map} />

                <p>
                  Rua Diretor Augusto Pestana 2250 <br />
                  Canoas - RS/Brasil
                </p>
              </div>

              <div className="space-y-6 mt-6">
                <Kicker>São Paulo</Kicker>

                <p>
                  Rua Alberto Guizzo 151
                  <br /> Indaiatuba - SP/Brasil
                </p>
              </div>
            </div>

            <div>
              <div className="flex items-center space-x-2">
                <FaHeadset className="text-2xl mb-6 text-accent-500" />
                <Heading level="3">Telefones</Heading>
              </div>
              <ul className="list-none">
                <li className="text-lg font-bold text-primary-500">
                  <span className="block uppercase text-xs font-bold">
                    Rio Grande do Sul
                  </span>
                  (51) 3425 8666{" "}
                </li>
                <li className="text-lg font-bold text-primary-500">
                  <span className="block uppercase text-xs font-bold">
                    São Paulo
                  </span>
                  (19) 3936 6979
                </li>
                {/* <li>
                  <span className="block uppercase text-xs font-bold text-primary-500">
                    VOIP:
                  </span>{" "}
                  (19) 4062 9520
                </li>
                <li>
                  <span className="block uppercase text-xs font-bold text-primary-500">
                    VOIP:
                  </span>{" "}
                  (51) 4063 8666
                </li>
                <li>
                  <span className="block uppercase text-xs font-bold text-primary-500">
                    Fax:
                  </span>{" "}
                  (51) 3428.2097
                </li> */}
                <li>
                  <span className="block uppercase text-xs font-bold text-primary-500">
                    Celular:
                  </span>{" "}
                  (51) 9306.2551
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export const Input = ({ id, placeholder, type = "text" }) => (
  <label htmlFor={id}>
    <span className="hidden">{placeholder}</span>
    <input
      id={id}
      name={id}
      type={type}
      className="mb-2 outline-none w-full px-4 py-2 border-gray-300 border rounded-sm flex items-center placeholder-gray-500 focus:text-primary-500 focus:border-primary-500"
      placeholder={placeholder}
    />
  </label>
)

export const TextArea = ({ id, placeholder }) => (
  <label htmlFor={id}>
    <span className="hidden">{placeholder}</span>
    <textarea
      id={id}
      rows="4"
      name={id}
      className="mb-6 outline-none w-full px-4 py-2 border-gray-300 border rounded-sm flex items-center placeholder-gray-500 focus:text-primary-500 focus:border-primary-500"
      placeholder={placeholder}
    />
  </label>
)

const tempData = {
  title: "Contato",
  heading: "Fale com a Bondmann",
  content:
    "Utilize o formulário abaixo para entrar em contato com a equipe da Bondmann. Em breve alguém de nossa equipe irá retornar seu contato.",
}

const staticQuery = graphql`
  query {
    map: file(relativePath: { eq: "bondmann-mapa.png" }) {
      asset: childImageSharp {
        fluid(maxWidth: 600, maxHeight: 400, quality: 84) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    banner: file(relativePath: { eq: "trabalhe-conosco-bg.png" }) {
      asset: childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Contato
