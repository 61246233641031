import React from "react"
import {
  BlockContent,
  Button,
  Heading,
  BackgroundImage,
  Link,
  Section,
} from "components"
import { useStaticQuery, graphql } from "gatsby"

const LeafletPage = ({ data }) => {
  const { allSanityLeaflet: leaflets } = useStaticQuery(staticQuery)

  return (
    <Section>
      {leaflets.edges.map(({ node: leaflet }) => {
        return (
          <div className="border rounded-sm mb-6">
            <div className="flex flex-wrap">
              <div className="w-full h-64 lg:h-auto lg:w-1/2 xl:w-1/4 relative">
                <Link to={leaflet.leafletFile.asset.url}>
                  <BackgroundImage source={leaflet.image} />
                </Link>
              </div>

              <div className="p-8 w-full lg:w-1/2 xl:w-3/4">
                <Link to={leaflet.leafletFile.asset.url}>
                  <Heading level="3">{leaflet.title}</Heading>
                </Link>

                <div>
                  <BlockContent blocks={leaflet._rawContent} />
                </div>

                <Button to={leaflet.leafletFile.asset.url} isPrimary>
                  Download
                </Button>
              </div>
            </div>
          </div>
        )
      })}
    </Section>
  )
}

const staticQuery = graphql`
  {
    allSanityLeaflet {
      edges {
        node {
          id
          title
          slug {
            current
          }
          _rawContent
          leafletFile {
            asset {
              url
            }
          }
          image {
            asset {
              fluid(maxWidth: 250, maxHeight: 350) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default LeafletPage
