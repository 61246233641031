import React from "react"
import { Heading, Image, Link, Section } from "components"
import { useStaticQuery, graphql } from "gatsby"

const NewsPage = () => {
  const { allSanityNews, allSanityLeaflet } = useStaticQuery(staticQuery)

  let content = [...allSanityNews.edges, ...allSanityLeaflet.edges]

  console.log(content)

  return (
    <Section>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {content.map(({ node }) => {
          const contentType = node.leafletFile ? "/catalogos/" : "/noticias/"

          console.log(contentType)
          return (
            <div className="shadow-soft rounded-sm border-b-4 border-accent-500">
              <div className="relative bg-gradient-t-black-50">
                <Link to={`${contentType}${node.slug.current}`}>
                  <Image className="opacity-75" source={node.image} />
                </Link>
              </div>
              <div className="p-8 space-y-2">
                <Heading className="text-2xl" level="3">
                  <Link to={`${contentType}${node.slug.current}`}>
                    {node.title}
                  </Link>
                </Heading>
              </div>
            </div>
          )
        })}
      </div>
    </Section>
  )
}

const staticQuery = graphql`
  {
    allSanityNews(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: published, order: DESC }
    ) {
      edges {
        node {
          title
          image {
            asset {
              fluid(maxWidth: 300, maxHeight: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          day: published(locale: "pt-br", formatString: "DD")
          month: published(locale: "pt-br", formatString: "MMMM")
          year: published(locale: "pt-br", formatString: "YYYY")
          _rawContent
        }
      }
    }
    allSanityLeaflet(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: published, order: DESC }
    ) {
      edges {
        node {
          title
          image {
            asset {
              fluid(maxWidth: 300, maxHeight: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
          leafletFile {
            asset {
              title
              url
            }
          }
          slug {
            current
          }
          day: published(locale: "pt-br", formatString: "DD")
          month: published(locale: "pt-br", formatString: "MMMM")
          year: published(locale: "pt-br", formatString: "YYYY")
          _rawContent
        }
      }
    }
  }
`

export default NewsPage
